import React from "react";
// import { Outlet } from "react-router-dom";
import { useEffect, useState } from "react";
import * as API from "../utills/API";
import { Card} from "@mui/material";
import Loading from "../components/Loading";

function LoggedInProfile() {
  const [userLoaded, setUserLoaded] = useState(false);
  const [user, setUser] = useState([]);
  // const [tableData, setTableData] = useState([]);
  // const [loadTableData, setLoadTableData] = useState(false);
  useEffect(() => {
    setUserLoaded(false);
    API.GetMethod("getUserContext").then((user_data) => {
      API.GetMethod("user/" + user_data.data._id).then((res) => {
        setUser(res);
        
        // setLoadTableData(false);
        API.GetMethod("menu").then((tableRes) => {
          let editTableDate = JSON.parse(JSON.stringify(tableRes.data));
          for (let index = 0; index < editTableDate.length; index++) {
            for (
              let alpha = 0;
              alpha < res.data[0].role_details.permissions.length;
              alpha++
            ) {
              let newindex = editTableDate[index].forms.findIndex(
                (x) =>
                  x.form_id ===
                  res.data[0].role_details.permissions[alpha].form_id
              );
              editTableDate[index].forms[newindex] =
                res.data[0].role_details.permissions[alpha];
            }
            // setTableData(editTableDate);
          }
          // setLoadTableData(true);
        });
        setUserLoaded(true);
      });
    });
  }, []);

  return (
    <div className="d-flex flex-column flex-grow-1 mer-margin-20">
      {userLoaded ? (
        <div>
          <div className="d-flex" style={{ fontSize: 20 }}>
            User Profile
          </div>
          <div className="d-flex flex-column mer-margin-top-10 col-12 col-sm-12 mer-mobile-width-percentage-100 col-md-6 col-lg-6 col-xl-6">
          <Card className="mer-margin-right-8 mer-margin-bottom-8 flex-grow-1 secondary">
            <div className="d-flex flex-row justify-content-between mer-width-percentage">
              <div>Name</div>
              <div>{user.data[0].user_name}</div>
            </div>
            <div className="d-flex flex-row justify-content-between mer-width-percentage">
              <div>Role</div>
              <div>{user.data[0].role_name}</div>
            </div>
            <div className="d-flex flex-row justify-content-between mer-width-percentage">
              <div>Functional Group</div>
              <div>{user.data[0].notification_group_name}</div>
            </div>
            {/* <div className="d-flex flex-row justify-content-between mer-width-percentage">
              <div>Designation</div>
              <div>{user.data[0].designation}</div>
            </div> */}
            {/* <div className="d-flex flex-row justify-content-between mer-width-percentage">
              <div>Domain</div>
              <div>Domain</div>
            </div>
            <div className="d-flex flex-row justify-content-between mer-width-percentage">
              <div>Department</div>
              <div>{user.data[0].emp_details[0].department_name}</div>
            </div>
            <div className="d-flex flex-row justify-content-between mer-width-percentage">
              <div>Sub-Department</div>
              <div>{user.data[0].emp_details[0].sub_department_name}</div>
            </div> */}
            {/* <div className="d-flex flex-row justify-content-between mer-width-percentage">
              <div>Actions</div>
              <div className="mer-max-width-350"> {CommonMethods.arrayToString(
                      user.data[0].role_details.actions_info,
                      "action_name"
                    )}</div>
            </div> */}
            {/* <div className="d-flex flex-row justify-content-between mer-width-percentage">
              <div>Pay Details Access</div>
              {user.data[0].role_details.pay_details_access?<div>Yes</div>:<div>No</div>}
              
            </div> */}
            </Card></div>
          {/* <div className="mer-margin-top-20">Page Permissions</div> */}
          {/* <div className="mer-flexdirection-2">
              <Accordion  
                className="mer-margin-top-10"
                defaultExpanded={true}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    >
                      <div className="mer-padding-left-20">
                        Header
                      </div>
                  </AccordionSummary>
                  <AccordionDetails>
                    <TableContainer>
                      <Table
                        stickyHeader
                        aria-label="sticky table"
                        size="small"
                        >
                        <TableBody>
                          {CommonMethods.headerData.map((header,index) =>(
                            <React.Fragment>
                              <TableRow key={index}>
                                <TableCell className="mer-width-percentage-50">{header.name}</TableCell>
                                <TableCell align="start">
                                      {user.data[0].headers[header['form_control_name']] ? (
                                        <RiCheckFill className="mer-width-20 mer-height-20"></RiCheckFill>
                                      ) : null}
                                    </TableCell>
                              </TableRow>
                            </React.Fragment>
                          ))}
                        </TableBody>   
                      </Table>
                    </TableContainer>
                  </AccordionDetails>
              </Accordion>
            </div> */}
          {/* {loadTableData ? (
            tableData.map((permission, parent_index) => {
              return (
                <Accordion className="mer-margin-top-10" defaultExpanded={true}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <div className="mer-padding-left-20">
                      {permission.menu_name}
                    </div>
                  </AccordionSummary>
                  <AccordionDetails>
                    {isNullOrUndefined(permission.forms[0]) ? (
                      <div className="d-flex align-items-center justify-content-center">
                        No data to display
                      </div>
                    ) : (
                      <TableContainer>
                        <Table
                          stickyHeader
                          aria-label="sticky table"
                          size="small"
                          className="mer-table"
                        >
                          <TableHead>
                            <TableCell></TableCell>
                            <TableCell align="center">
                              <div>All Permissions</div>
                            </TableCell>
                            <TableCell align="center">
                              <div>Page Level Access</div>
                            </TableCell>
                            <TableCell align="center">
                              <div>Can View</div>
                            </TableCell>
                            <TableCell align="center">
                              <div>Can Edit</div>
                            </TableCell>
                            <TableCell align="center">
                              <div>Can Download</div>
                            </TableCell>
                          </TableHead>
                          <TableBody>
                            {permission.forms.map((user, index) => (
                              <React.Fragment>
                                <TableRow
                                  key={index}
                                  sx={{
                                    "&:last-child td, &:last-child th": {
                                      border: 0,
                                    },
                                  }}
                                >
                                  <TableCell>{user.form_name}</TableCell>
                                  <TableCell align="center">
                                    {user.all_access ? (
                                      <RiCheckFill className="mer-width-20 mer-height-20"></RiCheckFill>
                                    ) : null}
                                  </TableCell>
                                  <TableCell align="center">
                                    {user.page_level_access ? (
                                      <RiCheckFill className="mer-width-20 mer-height-20"></RiCheckFill>
                                    ) : null}
                                  </TableCell>
                                  <TableCell align="center">
                                    {user.can_view ? (
                                      <RiCheckFill className="mer-width-20 mer-height-20"></RiCheckFill>
                                    ) : null}
                                  </TableCell>
                                  <TableCell align="center">
                                    {user.can_edit ? (
                                      <RiCheckFill className="mer-width-20 mer-height-20"></RiCheckFill>
                                    ) : null}
                                  </TableCell>
                                  <TableCell align="center">
                                    {user.can_download ? (
                                      <RiCheckFill className="mer-width-20 mer-height-20"></RiCheckFill>
                                    ) : null}
                                  </TableCell>
                                </TableRow>
                                {!isNullOrUndefined(user.sections)
                                  ? Object.keys(user.sections).map((key) => {
                                      return (
                                        <TableRow>
                                          <div className="d-flex flex-row  align-items-center mer-padding-left-25">
                                            {user.sections[key] ? (
                                              <RiCheckFill className="mer-width-20 mer-height-20"></RiCheckFill>
                                            ) : null}
                                            <div
                                              className="d-flex "
                                              style={{ marginRight: "10px" }}
                                            >
                                              {CommonMethods.CapitalizeFirstLetter(key.replace('_'," "))}
                                            </div>
                                          </div>
                                        </TableRow>
                                      );
                                    })
                                  : null}
                              </React.Fragment>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    )}
                  </AccordionDetails>
                </Accordion>
              );
            })
          ) : (
            <div className="d-flex mer-fill align-items-center justify-content-center">
              {" "}
              Data is loading....
            </div>
          )} */}
        </div>
      ) : (
        <div className="d-flex mer-fill align-items-center justify-content-center">
          <Loading />
        </div>
      )}
    </div>
  );
}

export default LoggedInProfile;
