import * as CommonMethods from "../utills/CommonMethods";
import axios from "axios";
import { status_dialog } from "./Alerts";

const URL = process.env.REACT_APP_BASE_URL + "/api-desknotify/v1/";
const TeamsURL = process.env.REACT_APP_TEAMS_URL;


export const axiosHttp = axios.create({
  baseURL: `${URL}`,
});

export const axiosTeams = axios.create({
  baseURL: `${TeamsURL}`,
});



axiosHttp.interceptors.request.use((request) => {
  if (!CommonMethods.isNullOrUndefined(localStorage.getItem("mer-desk-alerts-auth-key"))) {
    request.headers['Authorization'] = `Bearer ${(localStorage.getItem("mer-desk-alerts-auth-key"))}`;
  }
  if (!CommonMethods.isNullOrUndefined(localStorage.getItem("mer-uuid"))) {
    request.headers['uuid'] = `${(localStorage.getItem("mer-uuid"))}`;
  }
  const d = new Date();
  let offset = d.getTimezoneOffset();
  request.headers['offset'] = offset;
  return request;
});

axios.interceptors.response.use(async (response) => {
  return response;
}, async (error) => {

  
  if(!CommonMethods.isNullOrUndefined(error)){
    if (error.response && ((error.response.data && error.response.data.status === 401) || error.response.status === 401)) {
      
      let status_details = {...CommonMethods.STATUS_POPUP_DETAILS.error, message: error?.response?.data?.message }
      status_dialog(status_details, async() => {
        localStorage.clear();
        // navigate('login');
        await CommonMethods.Logout();
        return Promise.reject(error);
      });

      //return Promise.reject(error.response)
      // <CustomSnakebar verticalPosition="bottom" horizontalPosition="center" message={error.response.data.message}></CustomSnakebar>
    } else if (error.response && error.response.data && error.response.status === 403) {
      return error.response;
    }
    else if(error.response && ((error.response.data && error.response.data.status === 500) || error.response.status === 500)){
      let status_details = {...CommonMethods.STATUS_POPUP_DETAILS.error, message: error?.response?.data?.message }
      status_dialog(status_details, () => {
        return Promise.reject(error);
      });
    }
    else if(error.response && ((error.response.data && error.response.data.status === 400) || error.response.status === 400)){
      let status_details = {...CommonMethods.STATUS_POPUP_DETAILS.error, message: error?.response?.data?.data[0] }
      status_dialog(status_details, () => {
        return Promise.reject(error);
      });
    }
    return Promise.reject(error) ;
    //return error.response ;
  }
});


export let GetMethod = async (url, params = {}) => {
  let getResponse = await axiosHttp.get(url, { params: { ...params } });
  return getResponse ? getResponse.data : null;
};

export const PostMethod = async (url, post_data, event_info) => {
  const config = {
    headers: event_info,
  };
  let postData = await axiosHttp.post(url, post_data, config);
  return postData.data;
};

export const PutMethod = async (url, post_data) => {
  return {};
};

export const DeleteMethod = async (url, event_info) => {
  const config = {
    headers: event_info,
  };
  let deleteData = await axiosHttp.delete(url, config);
  return deleteData ? deleteData.data : null;
};

export async function UpdateMessage(url, post_data) {
  let postData = await axiosTeams.post(url, post_data);
  return postData.data;
}
