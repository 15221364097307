import React, {useContext} from 'react'
import {CircularProgress,Box,Fab,} from '@mui/material';
import IMAGES from "../utills/constants/Images";
import { ThemeContext } from "../../src/App";
function Loading() {
  const Themeobj = useContext(ThemeContext);
  
  return (
    <div className='d-flex flex-column mer-fill flex-grow-1 justify-content-center align-items-center'>
      {  Themeobj.currentTheme==='light'?
        <Box sx={{ m: 1, position: 'relative' }}>
        <Fab
          aria-label="save"
          style={{backgroundColor:'#ffffff',boxShadow:'none'}}
              >
           <img height={'30px'} src={ IMAGES.accordion_icon } alt="svg" />            
        </Fab>
     
          <CircularProgress
            size={68}
            sx={{
              color:'#EEFB87',
              position: 'absolute',
              top: -6,
              left: -6,
              zIndex: 1,
            }}
          />
     
      </Box>
      :
      <Box sx={{ m: 1, position: 'relative' }}>
      <Fab
        aria-label="save"
        style={{backgroundColor:'#000000',boxShadow:'none'}}
            >
         <img height={'30px'} src={ IMAGES.accordion_icon_white } alt="svg" />            
      </Fab>
   
        <CircularProgress
          size={68}
          sx={{
            color:'white',
            position: 'absolute',
            top: -6,
            left: -6,
            zIndex: 1,
          }}
        />
   
    </Box>
          }
          Loading...
    </div>
  )
}

export default Loading

