
import axios from 'axios';
import * as CommonMethods from "../utills/CommonMethods";
import { status_dialog } from './Alerts';

const SetupInterceptors = (navigate) => {
  

  axios.interceptors.request.use((request) => {
    if (!CommonMethods.isNullOrUndefined(localStorage.getItem("mer-desk-alerts-auth-key"))) {
      request.headers['Authorization'] = `Bearer ${(localStorage.getItem("mer-desk-alerts-auth-key"))}`;
    }
    if (!CommonMethods.isNullOrUndefined(localStorage.getItem("mer-uuid"))) {
      request.headers['uuid'] = `${(localStorage.getItem("mer-uuid"))}`;
    }
    const d = new Date();
    let offset = d.getTimezoneOffset();
    request.headers['offset'] = offset;
    return request;
  })

  axios.interceptors.response.use(async (response) => {
    return response;
  }, async (error) => {

    
    if(!CommonMethods.isNullOrUndefined(error)){
      if (error.response && ((error.response.data && error.response.data.status === 401) || error.response.status === 401)) {
        
        let status_details = {...CommonMethods.STATUS_POPUP_DETAILS.error, message: error?.response?.data?.message }
        status_dialog(status_details, () => {
          localStorage.clear();
          navigate('login');
          return Promise.reject(error);
        });

        //return Promise.reject(error.response)
        // <CustomSnakebar verticalPosition="bottom" horizontalPosition="center" message={error.response.data.message}></CustomSnakebar>
      } else if (error.response && error.response.data && error.response.status === 403) {
        return error.response;
      }
      else if(error.response && ((error.response.data && error.response.data.status === 500) || error.response.status === 500)){
        let status_details = {...CommonMethods.STATUS_POPUP_DETAILS.error, message: error?.response?.data?.message }
        status_dialog(status_details, () => {
          return Promise.reject(error);
        });
      }
      else if(error.response && ((error.response.data && error.response.data.status === 400) || error.response.status === 400)){
        let status_details = {...CommonMethods.STATUS_POPUP_DETAILS.error, message: error?.response?.data?.data[0] }
        status_dialog(status_details, () => {
          return Promise.reject(error);
        });
      }
      return Promise.reject(error) ;
      //return error.response ;
    }
  })
}

export default SetupInterceptors









