
import currency_rupee_svg from '../../assets/icons/currency_rupee.svg';
import documnets_svg from '../../assets/icons/documents.svg';
import interview_svg from '../../assets/icons/interview.svg';
import profile_svg from '../../assets/icons/profile.svg';
import proposal_svg from '../../assets/icons/proposal.svg';
import registration_svg from '../../assets/icons/registration.svg';
import tests_svg from '../../assets/icons/tests.svg';
import overview_svg from '../../assets/icons/candidate_overview.svg';

import department from '../../assets/icons/department.svg';
import designation from '../../assets/icons/designation.svg';
import domains from '../../assets/icons/domains.svg';
import roles from '../../assets/icons/roles.svg';
import sub_department from '../../assets/icons/sub_department.svg';
import users from '../../assets/icons/users.svg';

import branches from '../../assets/icons/branches.svg';
import colleges from '../../assets/icons/colleges.svg';
import degrees from '../../assets/icons/degrees.svg';
import resume_sources from '../../assets/icons/resume_sources.svg';

import action from '../../assets/icons/action.svg';
import cases from '../../assets/icons/cases.svg';
import email_templates from '../../assets/icons/email_templates.svg';
import events from '../../assets/icons/events.svg';
import smtp from '../../assets/icons/smtp.svg';
import stages from '../../assets/icons/stages.svg';
import status from '../../assets/icons/status.svg';

import analytics from '../../assets/icons/analytics.svg';
import candidates from '../../assets/icons/candidates.svg';
import home from '../../assets/icons/home.svg';

import add from '../../assets/icons/add.svg';
import archive from '../../assets/icons/archive.svg';
import settings from '../../assets/icons/settings.svg';
import mobile_logo from '../../assets/images/merilytics_mobile_logo.svg';
import mobile_logo_dark from '../../assets/images/merilytics_mobile_logo_dark.svg';
import login_background from '../../assets/images/login_background.svg';
import desk_notify_logo_svg from '../../assets/icons/desk_notify_logo.svg';

import desk_notify_logo_new from '../../assets/icons/desk_notify_logo_new.svg';
import access_denied_svg from '../../assets/images/access_denied.svg';


import online from '../../assets/icons/online.svg';
import offline from '../../assets/icons/offline.svg';
import group from '../../assets/icons/group.svg';

import gesture_select  from '../../assets/icons/gesture_select.svg';


import { ReactComponent as OnlineMeeting } from '../../assets/images/online_meeting.svg';

import { ReactComponent as Notification } from '../../assets/images/Notification.svg';

import notification_icon from '../../assets/icons/notification_icon.svg';
import device_management from '../../assets/icons/device_management.svg';
import settings_pencil from '../../assets/icons/settings_pencil.svg';
import survey from '../../assets/icons/survey.svg';
import Merilytics_Logo_Color from '../../assets/images/Merilytics_Logo_Color.svg';
import metrics from '../../assets/icons/metrics.svg';
import accordion_logo from '../../assets/images/accordion_logo.svg';
import accordion_login_background from '../../assets/images/accordion_login_page_background.svg';
import accordion_logo_white from '../../assets/images/accordion_logo_white.svg';

import accordion_icon from '../../assets/images/accordion_icon.svg';
import accordion_icon_white from '../../assets/images/accordion_icon_white.svg';
import accordion_icon_disabled from '../../assets/images/accordion_A_outer_disabled.svg';
import microsoft_logo from "../../assets/images/microsoft_logo.svg"
import sign_in_with_microsoft_light from "../../assets/images/sign_in_with_microsoft_light.svg"
import sign_in_with_microsoft_dark from "../../assets/images/sign_in_with_microsoft_dark.svg"

const IMAGES = {
    "microsoft_logo": microsoft_logo,
    "microsoft_login_image_light":sign_in_with_microsoft_light,
    "microsoft_login_image_dark":sign_in_with_microsoft_dark,
    "accordion_login_background": accordion_login_background,
    "accordion_logo":accordion_logo,
    "accordion_logo_white":accordion_logo_white,
    "accordion_icon":accordion_icon,
    "accordion_icon_white":accordion_icon_white,
    "accordion_icon_disabled":accordion_icon_disabled,
    "merilytics_logo_new": Merilytics_Logo_Color,
    "assets_merilytics_mobile_logo_dark": mobile_logo_dark,
    "assets_merilytics_mobile_logo": mobile_logo,
    "online_meeting": <OnlineMeeting />,
    "notification": <Notification />,
    "access_denied": access_denied_svg,
    "recruitment_with_merilytics_logo": process.env.REACT_APP_BASE_URL + "/files/static/recruitment_with_merilytics_logo.svg",
    "recruitment_with_merilytics_logo_dark": process.env.REACT_APP_BASE_URL + "/files/static/recruitment_with_merilytics_logo_dark.svg",
    "login_background":login_background,
    "desk_notify_logo_svg":desk_notify_logo_svg,
    "desk_notify_logo_new":desk_notify_logo_new,
    "sidemenu": {
        "overview": overview_svg,
        "registration": registration_svg,
        "profile": profile_svg,
        "tests": tests_svg,
        "interviews": interview_svg,
        "documents": documnets_svg,
        "proposal": proposal_svg,
        "currency_rupee": currency_rupee_svg,

        "home": home,
        "candidates": candidates,
        "analytics": analytics,

        "add": add,
        "archive": archive,
        "settings": settings,

        "roles": roles,
        "users": users,
        "domains": domains,
        "departments": department,
        "sub_departments": sub_department,
        "designations": designation,
        "resume_sources": resume_sources,
        "colleges": colleges,
        "degrees": degrees,
        "branches": branches,
        "events": events,
        "email_templates": email_templates,
        "smtp": smtp,
        "stages": stages,
        "status": status,
        "cases": cases,
        "actions": action,

        "notification_icon":notification_icon,
        "survey":survey,
        "group":group,
        "device_management":device_management,
        "settings_edit": settings_pencil,
        "metrics": metrics,
    },
    "online":online,
    "offline":offline,
    "group":group,
    "gesture_select":gesture_select
}

export default IMAGES;