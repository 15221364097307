import { useEffect, useState } from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";



const IsLoginGuard = () => {
    const isAuthenticated = localStorage.getItem("mer-desk-alerts-auth-key");
    
    const [location,setLocation] = useState(useLocation());
    
    useEffect(()=>{
        isAuthenticated ? <Navigate  to="/deskalerts" /> : <Outlet />
    },[location])
    
    return (
      isAuthenticated ? <Navigate  to="/deskalerts" /> : <Outlet />
    );
}

export default IsLoginGuard