import { createTheme, StyledEngineProvider, ThemeProvider } from '@mui/material';
import { createContext, useState } from 'react';
import './App.scss';
import Routing from "./Routing";
import { CssBaseline } from "@mui/material";
import { useNavigate } from 'react-router-dom';
import SetupInterceptors from "./utills/API-interceptor";
import { ConfirmationDialog, SimpleDialog, StatusDialog } from './utills/Alerts';
// import createBreakpoints from '@material-ui/core/styles/createBreakpoints';

export const ThemeContext = createContext(null);
export const SideMenuContext = createContext(null);
export const LoggedInUserContext = createContext({});
const theme = createTheme();



// function AxiosInterceptorNavigate() {
//   let navigate = useNavigate();
//   SetupInterceptors(navigate);
//   return <></>;
// }

function App() {
  
  const [sideMenu, setSideMenu] = useState({ "open": true, sideMenuItems: [] });

  const [currentTheme, setCurrentTheme] = useState(
    localStorage.getItem('mer-auth-key') ?
    (localStorage.getItem('selectedTheme') ?
    localStorage.getItem('selectedTheme') :
   (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches ? "dark" : "light")):"light")

  const themes = {
    "light": {
      palette: {
        mode: "light",
        primary: {
          main: '#1F242F',
          secondary: "#D3D3D3",
        },
        secondary: {
          main: '#ffffff' // Secondary using for buttons
        },
        text: {
          primary: "#000000",
        },
        MuiCardContent: {
          primary: '#e7e0ec'
        },
        background: {
          default: '#FFFFFF',
        },
        action: {
          active: "#1F242F",

        },
      },
      typography: {
        h6: {
          fontSize: '24px',
          fontWeight: '600'
        },
        button: {
          //fontWeight:500
          color: '#ffffff',
          textTransform: 'none'
        },

      },
      
      components: {
        MuiFormLabel: {
          styleOverrides: {
            root: {
          fontSize: '12px !important',
          fontWeight: '600',
          color: 'black'
            }
          }
        },
        MuiTypography: {
          styleOverrides: {
            root: {
              // fontWeight: '600',
              "&.bluetext": {
                color: "#6750A4",
                fontSize: '16px',
                fontWeight: '500'
              }
            }
          }

        }, 
        MuiSelect:{
          styleOverrides: {
            root: { 
              height:'35px',
            }
          }
        },
        MuiInputBase: {
          styleOverrides: {
            root: {
              "&.globalsearch": {
                background: '#E5E5E5',
                borderRadius: '34px',
                color: '#1F242F',
                [theme.breakpoints.up('lg')]: {
                  width: '400px'
                },

              },
              "&.tablesearch": {
                height: '35px',
                [theme.breakpoints.up('md')]: {
                  width: '250px',
                },
                background: "#E5E5E5",
                borderRadius: "34px",
                color: "#1F242F",
                padding: "0px 16px",
              },
              "&.MuiOutlinedInput-root": {
                padding: "0px 4px 0px 0px",
              }

            },
            input: {
              "&.MuiOutlinedInput-input": {
                paddingLeft: '6px',
                "&.MuiOutlinedInput-input": {
                  paddingTop: '6px',
                  // paddingRight: '6px'
                }
              }
            }


          }
        },
        MuiTablePagination: {
          styleOverrides: {
            select: {
              paddingRight: '20px !important',
              marginRight: '15px !important'
            },
            displayedRows:{
              marginBottom: "0px !important"
            },
            selectLabel:{
              marginBottom: "0px !important"
            }
          }
        },
        MuiDialogActions: {
          styleOverrides: {
            root: {
              "&.confirm-action-dialog-box": {
                justifyContent: 'center !important'
              }
            }
          }
        },
        MuiToolbar: {
          styleOverrides: {
            root: {
              background: '#ffffff',
              color: '#000000',
              "&.MuiTablePagination-toolbar": {
                minHeight: '22px',
                background: '#818995',
                margin: '10px',
                height: '40px',
                color: '#FFFFFF'
              }
            }

          }
        },
        MuiTableRow:{
          styleOverrides: {
            root: {
              //background: 'linear-gradient(0deg, rgba(86, 85, 168, 0.05), rgba(86, 85, 168, 0.05)), #FFFBFF',
              padding: '6px 6px !important',
              background: "#F2F2F2",
              "&:hover": {
                backgroundColor: "#E5E5E5;",
              },
              "&.mer-expanded-row":{
                background: 'none !important',
                "&:hover":{
                  backgroundColor:'#eeeef2;',
                },
              },
              "&.mer-sub-table": {
                background: '#FFFFFF !important',
              },
              "&.mer-sub-table-header": {
                background: '#E5E1E6 !important',
                
              },
            }
          }
        },
        MuiTableCell: {
          styleOverrides: {
            root: {
              borderBottom: '2px solid white',
            
               },
            head: {
              //background: '#E8DEF8',
              fontSize: '14px',
              fontWeight: '700',
              background: "#818995",
              color: "#FFFFFF",
              fontsize: "16px",
              fontweight: "500",
              "&:hover": {
                color: "#FFFFFF !important",
              },
              "&.mer-theme-onepagertablehead": {
                background: "#F8FDCE",
                color:"#1F242F"
              },
              "&.mer-sub-table-header": {
                background: '#B2B2B2 !important',
                color:"#1F242F !important"
              },
            },
          },
        },
        MuiButton: {
          styleOverrides: {
            root: {
              // background: '#6750A4',
              color: '#1F242F',
              background: '#EEFB87',
              borderRadius: '100px',
              height: '35px',
              "&:hover": {
                background: '#818995',
                color: '#ffffff',
              },
             "&.textcls": {
                background: "None",
                color: "#1F242F",
              },
              "&.outlinedcls": {
                background: "None",
                border: "1px solid #1F242F",
                color: "#1F242F",
              },
              "&.mer-theme-viewprofile": {
                background: 'None',
                border: '1px solid #FFFFFF',
                color: '#FFFFFF'
              },
              "&.mer-theme-logout": {
                background: '#EEFB87',
                color: '#1F242F'
              },
              "&.mer-theme-danger-button": {
                background: '#d32f2f',
                borderRadius: '100px',
                color: '#fff'
              },

            }
          }
        },
        MuiTab: {
          styleOverrides: {
            root: {
              minHeight: "50px",
              // color:'red',
              // "&.Mui-selected": {
              //   color: '#5655A8',
              //   borderRadius: "25px"
              // },
              "&:hover": {
                color: '#1F242F'
              }
            }
          }
        },
        MuiTabs: {
          styleOverrides: {
            indicator: {
              background: '#EEFB87',
              height: '4px',
            }
          }
        },
        MuiPaper: {
          styleOverrides: {
            root: {
              MuiDialog: {
                paper: {
                  "&.dialog-box": {
                    width: '50% !important',
                    minHeight: '60% !important',
                    maxHeight: 'auto !important'
                  }
                }
              }
            }
          }
        },

        MuiDrawer: {
          styleOverrides: {
            root: {
              height: '100%',

              ".css-1egvwlz-MuiPaper-root-MuiDrawer-paper": {
                height: `calc(100% - 60px) !important`,



              }


            },
            paper: {
              height: `calc(100% - 50px) !important`
            }
          }
        },
        MuiList: {
          styleOverrides: {
            root: {
              paddingTop: '0',
              ".mer-theme-sub-list-item": {
                background: '#ddd !important'
              },
              "&.MuiMenu-list": {
                paddingBottom: "0px"
              }
            },
          }
        },
        MuiListItem: {
          styleOverrides: {
            root: {
              color: "#1F242F",
              marginTop: "4px",
              marginBottom: "4px",
              paddingLeft: "12px",
              paddingRight: "12px",
              // borderBottom: "1px solid #ddd",
              "&.stageslistItem": {
                backgroundColor: '#E5E5E5',
                borderRadius: '100px',
                color: '#47464F',
                cursor: 'pointer'

              },
              "&:hover": {
                backgroundColor: "#F1FC9C",
                color: "#1D192B",
                borderRadius: "100px",
                "&.stageslistItem": {
                  backgroundColor: '#7776b8',
                  borderRadius: '100px',
                  color: 'white',
                  cursor: 'pointer'

                }
              },
              "&.active": {
                backgroundColor: "#EEFB87",
                // color: "#1D192B",
                color: '#1F242F',
                borderRadius: "100px",

              },
              "&.Mui-selected": {
                "&.stageslistItem": {
                  backgroundColor: '#EEFB87',
                  borderRadius: '100px',
                  color: 'white',
                  cursor: 'pointer'

                }
              }

            }

          }
        },
        MuiCard: {
          styleOverrides: {
            root: {
              // background: 'linear-gradient(0deg, rgba(86, 85, 168, 0.08), rgba(86, 85, 168, 0.08)), #FFFBFF',
              background:"#FFFFFF",
              margin: '12px',
              padding: '0px',
              borderRadius: '24px',
              overflow: 'visible',
              boxShadow: '0px 0px',
              "&.secondary": {
                background: '#FFFFFF',
                margin: '0px 12px 12px 0px',
                padding: '12px',
                borderRadius: '8px',
                boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.3), 0px 1px 3px 1px rgba(0, 0, 0, 0.15)'

              },
              "&.mer-white": {
                background: '#FFFFFF',
                margin: '8px',
                borderRadius: '8px',
              },
              "&.expiry": {
                // background: '#FFDAD6',
                background:"#EADCF5",
                margin: '0px 12px 12px 0px',
                padding: '12px',
                borderRadius: '8px',
                boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.3), 0px 1px 3px 1px rgba(0, 0, 0, 0.15)'
              },
              
              "&.candDetails": {

                margin: '0px 12px 12px 12px',
                background: '#EADCF5',
                borderRadius: '0px 0px 16px 16px'

              },
              "&.chipsTheme": {

                background: '#F2F2F2',
                borderRadius: '8px'

              },
              "&.teritary": {
                background: '#E5E5E5',
                margin: '0px 12px 12px 12px',
                borderRadius: '16px'
              },
              "&.mer-theme-headermenucard": {
                background: '#818995',
                borderRadius: '8px',
                color: '#FFFFFF'
              },
              "&.mer-theme-profilemenu": {
                background: '#3E3C8F',
                borderRadius: '24px',
                boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.3), 0px 1px 3px 1px rgba(0, 0, 0, 0.15)'

              },
              "&.mer-theme-loggedinas": {
                background: '#6F6EC3',
                borderRadius: '8px',
                color: '#FFFFFF',
              },

              "&.mer-theme-switchrole": {
                background: '#FFFFFF',
                borderRadius: '8px',
                color: '#1C1B1F',
                boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.3), 0px 1px 3px 1px rgba(0, 0, 0, 0.15)'
              },
              "&.mer-action-card": {
                padding: '4px 12px',
                margin: '0px 0px 6px 0px',
                borderRadius: '8px',
                background: '#FFFFFF',
                boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.3), 0px 1px 3px 1px rgba(0, 0, 0, 0.15)'
              },
              "&.mer-theme-success-card": {
                background: '#CEF14F',
                color:'#171E00',
                borderRadius: '8px'
              },
              "&.mer-theme-error-card": {
                background: '#FFDAD6',
                color:'#410002',
                borderRadius: '8px'
              },
              "&.mer-theme-primary-card": {
                background: '#818995',
                color:'#FFFFFF',
                borderRadius: '8px'
              },
              "&.mer-theme-logincard": {
                background: '#FFFFFF',      
                 color:"#1F242F",       
                borderRadius: '16px',
                padding: '12px',
              },
              "&.mer-theme-dialog-header-bg":{
                margin: '0px',
                padding: '0px',
                borderRadius: '0px',
                overflow: 'visible',
                boxShadow: '0px 0px',
                 background:'#E2DFFF'
              },
              "&.mer-theme-dialog-header-bg-tables":{
                margin: '0px',
                padding: '0px',
                borderRadius: '0px',
                overflow: 'visible',
                boxShadow: '0px 0px',
                background:'#E2DFFF' 
              }
            }
          }
        },

        MuiChip: {
          styleOverrides: {
            root: {
              backgroundColor: '#F5F5F5',
              color: '#1F242F',
              cursor: 'pointer',
              margin: '0px 8px',
              "&.active": {
                color:"#FFFFFF",

                backgroundColor: "#818995",
              },
              "&.mer-disabled-step": {
                color: 'rgba(0, 0, 0, 0.26)',
                boxShadow: 'none',
                backgroundColor: 'rgba(0, 0, 0, 0.12)'
              }
            },
            avatar: {
            color: "#1F242F",
              "&.mer-disabled-step": {
                color: 'rgba(0, 0, 0, 0.26)'
              }

            }
          }
        },
        MuiAutocomplete: {
          styleOverrides: {

            root: {
              "&.MuiAutocomplete-input": {
                padding: '0.5px 4px 2.5px 6px'
              },
              "&.gsearch": {
                marginTop: '10px'
              }
            },
            tag: {
              margin: '1px 2px 1px 2px'
            },
            endAdornment:{
              top:'6px'
            }
          }
        },
        MuiAccordion: {
          styleOverrides: {

            root: {
              width: '100%',
              [theme.breakpoints.down('md')]: {
                maxWidth: '367px'
              },
              "&.Mui-expanded": {
                width: '100%'
              }
            }
          }
        },
        MuiButtonBase: {
          styleOverrides: {

            root: {
              "&.MuiIconButton-root": {
                marginRight: '-3px',
                marginTop: '4px',
                float: 'left',
                //padding:0,
                "&.errorclr": {
                  color: '#BA1A1A',
                },
                "&.disabled": {
                  color: 'ddd'
                }
              },
              "&.MuiTableSortLabel-root": {
                color: "#FFFFFF",
                "&:hover": {
                  color: "#FFFFFF !important",
                }
              },
              "MuiIconButton-root-MuiAutocomplete-popupIndicator.MuiIconButton-root": {
                marginTop: '7px'
              },
              "&.MuiAccordionSummary-root": {
                height: '34px',
                minHeight: '34px',
                color: 'white',
                 background: "#818995",
              },
              "&.MuiAccordionSummary-root.Mui-expanded": {
                height: '34px',
                minHeight: '34px',
                color: 'white',
                background: "#818995",
              },
              "MuiCheckbox-root": {
                "&.mer-checkbox": {
                  padding: '0 0 0 9px !important'
                },
              },
              "&.MuiMenuItem-root": {
                "&.mer-checkbox": {
                  paddingLeft: '16px !important'
                }
              }

            }
          }
        },
        // MuiAccordionSummary:{
        //   styleOverrides: {
        //     content:{
        //        background:'red',
        //       "&.Mui-expanded":{

        //       }
        //     }
        //   }
        // },

        MuiTableContainer: {
          styleOverrides: {
            root: {




            }
          }
        },
        MuiSvgIcon: {
          styleOverrides: {
            root: {
              height: '25px',
              "&.mer-checkbox": {
                width: '1.5rem !important',
                height: '1.5rem !important'
              },
              "&.MuiSelect-icon": {
                color: '#1F242F',
                // paddingLeft: '8px',
                width: 'auto',
                // position: 'static',
              },
              ".mer-pagination &.MuiSelect-icon": {
                color: "#FFFFFF",
              }

            }
          }
        },

        MuiInputAdornment: {
          styleOverrides: {
            root: {
              color: '#000000',
              paddingLeft: '10px',
              'MuiIconButton-root':{
                padding:0
              }

            }
          }
        },
        
        MuiPickersArrowSwitcher:{
          styleOverrides: {
            spacer: {
              width:'12px'
            }
          }
        },
      MuiDialogTitle:{
        styleOverrides: {
          root: {           
            "&.mer-theme-dialog-header-bg":{
             background: "#818995",
            },
            "&.mer-theme-dialog-header-bg-tables":{
              background: "#818995",

                color:"#FFFFFF"
            }
          }
      }
    },
       MuiSkeleton: {

          styleOverrides: {

            root: {

              backgroundColor: "white",

              "&.mer-theme-skel-tableheader": {

                backgroundColor: "#818995",

              },

              "&.mer-theme-skel-tabledata": {

                backgroundColor: "#F2F2F2",

              },

              "&.mer-theme-skel-btn": {

                backgroundColor: "#EEFB87",

              },

              "&.mer-theme-skel-menu": {

                backgroundColor: "#E5E5E5",

              },

              "&.mer-theme-skel-menuh": {

                backgroundColor: "#EEFB87",

              },

              "&.mer-theme-skel-view": {

                backgroundColor: "#ffffff",

              },

            },

          },

        },
        MuiSlider: {
          styleOverrides: {
            mark: {
              width: '9px',
              height: '9px',
              borderRadius: '50%',
              color: '#1F242F',
              zIndex: '2',

            },
            markActive: {
              width: '9px',
              height: '9px',
              borderRadius: '50%',
              color: 'white',
              backgroundColor: 'white',
              zIndex: '2'
            },

            thumb: {
              width: '36px',
              borderRadius: '100px',
              color: '#1F242F',
              marginLeft: '3px'
            },
            rail: {
              color: '#818995'
            }
          }
        },
        MuiPickersDay: {
          styleOverrides: {
            root: {
              backgroundColor: 'None',
              "&.Mui-selected": {
                color: 'White',
                backgroundColor: '#1F242F'
              },
              "&:hover":{
                color: 'White',
                backgroundColor: '#1F242F'
              }


            },
            today: {
              backgroundColor: '#ffffff'
            }
          }
        }
         // MuiYearPicker:{
      //   styleOverrides: {
      //     root:{
      //       background:'red',
      //       "&.PrivatePickersYear-root":{
      //         "&.Mui-selected":{
      //              color:'white !important'
      //              }
      //       }
      //       // ['&> div >button']: {
      //       //   "&.Mui-selected":{
      //       //     color:'white !important'
      //       //   }
      //       // }
      //     }
      //   }
      // },
      // MuiClockPicker:{
      //   styleOverrides: {
      //     root:{
      //       backgroundColor:'red',
      //       ['&>div >div>div>span']: {
      //         "&.Mui-selected":{
      //           color:'red !important'
      //         }
      //       }
      //     }
      //   }
      // },
      // MuiCalendarPicker:{
      //   styleOverrides: {
      //     root:{
      //       maxHeight:'290px'
      //     },
      //     viewTransitionContainer:{
      //       overflow:'hidden'
      //     }
      //   }





      // }
      }, 
    },
    "dark":{

    }  
  }

  return (
    <StyledEngineProvider injectFirst>
      <ThemeContext.Provider value={{ currentTheme, setCurrentTheme }}>
        <ThemeProvider theme={createTheme(themes[currentTheme])}>
          <SideMenuContext.Provider value={{ sideMenu, setSideMenu }}>
            <CssBaseline />
            <div className="mer-fill">
              <Routing />
              <StatusDialog />
              <SimpleDialog />
              <ConfirmationDialog />
              {/* <AxiosInterceptorNavigate /> */}
            </div>
          </SideMenuContext.Provider>
        </ThemeProvider>
      </ThemeContext.Provider>
    </StyledEngineProvider>
  );
}

export default App;
