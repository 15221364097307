import Button from "@mui/material/Button";
import { Dialog, DialogTitle, Card } from "@mui/material";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import create from "zustand";


import { Snackbar, Slide, Tooltip } from "@mui/material";
import React from "react";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import * as CommonMethods from "../utills/CommonMethods";

import * as API from "../utills/API";
import parse from 'html-react-parser';

import useDeviceProperties from "./hooks/useDeviceProperties";
import { VscChromeMinimize } from 'react-icons/vsc';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useSimpleDialogStore = create((set) => ({
  message: "",
  onSubmit: undefined,
  close: () =>
    set({
      onSubmit: undefined,
    }),
}));
const SimpleDialog = () => {
  const { message, onSubmit, close } = useSimpleDialogStore();
  return (
    <Dialog
      open={Boolean(onSubmit)}
      onClose={(_, reason) => {
        if (reason !== "backdropClick") {
          close();
        }
      }}
    >
      <DialogContent>
        <DialogContentText>{message}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          onClick={() => {
            onSubmit();
            close();
          }}
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export { SimpleDialog };

export const simple_dialog = (message, onSubmit) => {
  useSimpleDialogStore.setState({ message, onSubmit });
};

const useConfirmationDialogStore = create((set) => ({
  message: "",
  onSubmit: undefined,
  close: () =>
    set({
      onSubmit: undefined,
    }),
  button: "",
  note: "",
}));

const ConfirmationDialog = () => {
  const { message, note, onSubmit, close, button } =
    useConfirmationDialogStore();
  return (
    <Dialog
      className="confirm-action-dialog-box"
      open={Boolean(onSubmit)}
      onClose={(_, reason) => {
        if (reason !== "backdropClick") {
          close();
        }
      }}
    >
      <DialogContent>
        <DialogContentText>{message}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <div className="d-flex flex-row flex-wrap  mer-width-percentage-100 mer-padding-left-16 mer-padding-right-16 justify-content-end">
          {!CommonMethods.isNullOrUndefined(note) ? (
            <div
              className="d-flex  col-12 col-sm-6 col-md-6 col-lg-7 col-xl-7 align-items-center "
              style={{ whiteSpace: "pre-wrap", overflowWrap: "break-word" }}
            >
              <span className="mer-note">Note: </span>
              <span className="mer-font-size-12">{note}</span>
            </div>
          ) : null}
          <div className="d-flex  col-12 col-sm-6 col-md-6 col-lg-5 col-xl-5 justify-content-end align-items-center">
            <Button
              className="mer-margin-right-10 outlinedcls"
              onClick={() => {
                close();
              }}
            >
              <div className="mer-width-percentage-100">No</div>
            </Button>
            <Button
              className=""
              variant="contained"
              onClick={() => {
                onSubmit();
                close();
              }}
            >
              <div className="mer-width-percentage-100">{button}</div>
            </Button>
          </div>
        </div>
      </DialogActions>
    </Dialog>
  );
};

export const confirmation_dialog = (
  message,
  onSubmit,
  note,
  button = "Yes"
) => {
  useConfirmationDialogStore.setState({ message, onSubmit, note, button });
};
export { ConfirmationDialog };

const alertStore = create((set) => ({
  title: "",
  message: "",
  severity: "",
  open: undefined,
  close: () =>
    set({
      open: undefined,
    }),
}));

const AlertDialog = () => {
  const { title, message, severity, open, close } = alertStore();
  const handleClose = (event, reason) => {
    if ("clickaway" === reason) return;
    close();
  };
  return (
    <Snackbar
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      open={Boolean(open)}
      onClose={handleClose}
      autoHideDuration={CommonMethods.ALERT_TIMEOUT}
    >
      <Alert
        severity={severity}
        onClose={() => {
          handleClose();
        }}
      >
        <AlertTitle className="mer-font-bold">{title}</AlertTitle>
        {message}
      </Alert>
    </Snackbar>
  );
};

export { AlertDialog };
export const alert_snackbar = (title, message, severity, open) => {
  alertStore.setState({ title, message, severity, open });
};

const statusStore = create((set) => ({
  dialogDetails: {},
  onSubmit: undefined,
  close: () =>
    set({
      onSubmit: undefined,
    }),
}));

const StatusDialog = () => {
  const { dialogDetails, onSubmit, close } = statusStore();
  return (
    <Dialog
      className={dialogDetails?.className}
      open={Boolean(onSubmit)}
      onClose={(_, reason) => {
        if (reason !== "backdropClick") {
          close();
        }
      }}
    >
      <DialogTitle>
        <div className="d-flex flex-row align-items-center">
          <span>{dialogDetails?.iconName}</span>
          <div className="mer-margin-left-10 mer-font-size-18">
            {dialogDetails?.title}
          </div>
        </div>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {!CommonMethods.isNullOrUndefined(dialogDetails?.message) ? parse(dialogDetails?.message) : ''}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          onClick={() => {
            onSubmit();
            close();
          }}
        >
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export { StatusDialog };

export const status_dialog = (dialogDetails, onSubmit) => {
  statusStore.setState({ dialogDetails, onSubmit });
};

const roles_to_be_considered_for_store = create((set) => ({
  candidate: "",
  action: "",
  open: null,
  close: (status) =>
    set({
      open: null
    }),
  callback: undefined,
}));

function triggerApi(action, candidate, callback) {
  if (!CommonMethods.isNullOrUndefined(action.confirmation_api)) {
    API.PostMethod(action.confirmation_api, { action: action, candidate: candidate }).then((res) => {
      callback(res);
    });
  }
}



