import { Skeleton } from '@mui/material';
import React, { useEffect, useState } from 'react';


function Skeletontable(props) {
  const [itr_skelton_rows, setIterationrows] = useState([]);
  let number_of_rows = 8;
  useEffect(() => {
    let temp_itr = []
    for (let i = 0; i < (props?.number_of_rows ? props?.number_of_rows : number_of_rows); i++) {
      temp_itr.push(true);
    }
    setIterationrows(temp_itr);
  }, []);

  return (
    <>

      <div className='d-flex flex-column flex-grow-1 mer-margin-top-5 mer-margin-left-12 mer-margin-right-12'>
        {!props?.no_header && <div className='d-flex flex-row mer-margin-top-10'>
          <Skeleton variant="rounded" width={210} height={45} className='mer-skel-search mer-theme-skel-tabledata mer-margin-right-20'></Skeleton>
          {/* <Skeleton variant="rounded" width={110} height={35} className='mer-skel-search mer-theme-skel-btn '></Skeleton> */}
        </div>}
        <div className='d-flex flex-column mer-margin-top-10'>
          <Skeleton variant="rectangular" height={45} className='mer-skel-table mer-theme-skel-tableheader'></Skeleton>
        </div>
        {
          itr_skelton_rows.map((row, key) => (
            <div key={key} className='d-flex flex-column '>
              <Skeleton variant="rectangular" height={35} className='mer-skel-table mer-theme-skel-tabledata mer-margin-top-1'></Skeleton>
            </div>
          ))
        }



      </div>
    </>
  )
}

export default Skeletontable