
import { useEffect, useState } from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";

const AuthenticatedGuard = () => {
    const isAuthenticated = localStorage.getItem("mer-desk-alerts-auth-key");
    
     const [location,setLocation] = useState(useLocation());
    
     useEffect(()=>{
        isAuthenticated ? <Outlet /> : <Navigate  to="/login" />
     },[location])
    
    return (
      isAuthenticated ? <Outlet /> : <Navigate  to="/login" />
   );
}

export default AuthenticatedGuard