import React, { useState, useEffect } from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import IsLoginGuard from "./components/guards/IsLoginGuard";
import AuthenticatedGuard from "./components/guards/AuthenticatedGuard";
import PageAccessGuard from "./components/guards/PageAccessGuard";

import Loading from "./components/Loading";
import Skeletonview from "./components/Skeletons/Skeletonview";
import Skeletontable from "./components/Skeletons/Skeletontable";
// import RenderSurvey from './pages/render/RenderSurvey';
import LoggedInProfile from "./pages/LoggedInProfile";
import * as API from "./utills/API";
import { isNullOrUndefined } from "./utills/CommonMethods";

const HistoryTable = React.lazy(() => import("./pages/history/HistoryTable"));
const RenderNotifications = React.lazy(() =>
  import("./pages/render/RenderNotifications")
);
const SubmitComponent = React.lazy(() =>
  import("./pages/render/SubmitComponent")
);
const ViewAlert = React.lazy(() => import("./pages/home/alert/ViewAlert"));

const Home = React.lazy(() => import("./pages/home/Home"));
const Login = React.lazy(() => import("./pages/Login"));
const Configuration = React.lazy(() =>
  import("./pages/configuration/Configuration")
);
const DeskAlerts = React.lazy(() => import("./pages/DeskAlerts"));

const CreateAlert = React.lazy(() => import("./pages/home/alert/CreateAlert"));
const TableAlert = React.lazy(() => import("./pages/home/alert/TableAlert"));
const SentAlert = React.lazy(() => import("./pages/home/alert/SentAlert"));

const CreateSurvey = React.lazy(() =>
  import("./pages/home/survey/CreateSurvey")
);
const TableSurvey = React.lazy(() => import("./pages/home/survey/TableSurvey"));
const ViewSurvey = React.lazy(() => import("./pages/home/survey/ViewSurvey"));

const RecipientsTable = React.lazy(() =>
  import("./pages/devicemanagement/RecipientsTable")
);
const AlertTemplates = React.lazy(() =>
  import("./pages/configuration/templates/AlertTemplates")
);
const AlertTemplateForm = React.lazy(() =>
  import("./pages/configuration/templates/AlertTemplateForm")
);

const SurveyTemplates = React.lazy(() =>
  import("./pages/configuration/templates/SurveyTemplates")
);
const SurveyTemplateForm = React.lazy(() =>
  import("./pages/configuration/templates/SurveyTemplateForm")
);

const Users = React.lazy(() => import("./pages/configuration/users/Users"));
const UserView = React.lazy(() =>
  import("./pages/configuration/users/UserView")
);
const UserForm = React.lazy(() =>
  import("./pages/configuration/users/UserForm")
);

const UserRoles = React.lazy(() =>
  import("./pages/configuration/userRoles/UserRoles")
);
const UserRoleForm = React.lazy(() =>
  import("./pages/configuration/userRoles/UserRoleForm")
);
const UserRoleView = React.lazy(() =>
  import("./pages/configuration/userRoles/UserRoleView")
);

const Groups = React.lazy(() => import("./pages/configuration/groups/Groups"));
const GroupForm = React.lazy(() =>
  import("./pages/configuration/groups/GroupForm")
);
const GroupView = React.lazy(() =>
  import("./pages/configuration/groups/GroupView")
);
const CustomizedParameters = React.lazy(() =>
  import("./pages/configuration/customizedParameters/CustomizedParameters")
);
const CustomizedParameterView = React.lazy(() =>
  import("./pages/configuration/customizedParameters/CustomizedParameterView")
);
const CustomizedParameterForm = React.lazy(() =>
  import("./pages/configuration/customizedParameters/CustomizedParameterForm")
);
const Metrics = React.lazy(() =>
  import("./pages/configuration/metrics/Metrics")
);
const AccessDeniedPage = React.lazy(() =>
  import("./components/AccessDeniedPage")
);

function Routing() {
  const [metricsAccess, setMetricsAccess] = useState(false);
  const [rolesAccess, setRolesAccess] = useState(false);
  useEffect(() => {
    if (!isNullOrUndefined(localStorage.getItem("mer-desk-alerts-auth-key"))) {
      API.GetMethod("getUserContext").then((user_context_response) => {
        let user_data = !isNullOrUndefined(user_context_response["data"])
          ? user_context_response["data"]
          : {};
        let forms = !isNullOrUndefined(user_data["screens"])
          ? user_data["screens"]["forms"]
          : [];
        let metrics_found_flag = false;
        let roles_found_flag = false;
        for (let i = 0; i < forms.length; i++) {
          // console.log("form name", forms[i].form_name);
          if (forms[i].form_name === "Metrics") {
            setMetricsAccess(forms[i].page_level_access);
            metrics_found_flag = true;
          }
          if (forms[i].form_name === "Roles") {
            setRolesAccess(forms[i].page_level_access);
            roles_found_flag = true;
          }
        }
        if (metrics_found_flag === false) {
          setMetricsAccess(false);
        }
        if (roles_found_flag === false) {
          setRolesAccess(false);
        }
      });
    }
  });

  return (
    <React.Suspense fallback={<Loading />}>
      <Routes>
        <Route exact path="" element={<Navigate to="/login" />} />

        {/**
         * This is corresponding to the new version
         */}
        <Route
          path="/render-notifications/v2/:show_acknowledged_button/:user_name"
          element={<RenderNotifications />}
        ></Route>
        <Route
          path="/render-notifications/v2/:notification_id/:show_acknowledged_button/:user_name"
          element={<RenderNotifications />}
        ></Route>

        {/**
         * This is for backward compatibility
         */}
        <Route
          path="/render-notifications/:user_name/:show_acknowledged_button"
          element={<RenderNotifications />}
        ></Route>
        <Route
          path="/render-notifications/:notification_id/:user_name/:show_acknowledged_button/"
          element={<RenderNotifications />}
        ></Route>

        <Route
          path="/render-submit-component/:user_name/:is_alert"
          element={<SubmitComponent />}
        ></Route>
        <Route path="/render-loading" element={<Skeletontable />}></Route>
        <Route path="/history/:user_name" element={<HistoryTable />}></Route>
        <Route element={<IsLoginGuard />}>
          <Route path="/login" element={<Login />} />
        </Route>
        <Route element={<AuthenticatedGuard />}>
          <Route path="/deskalerts" element={<DeskAlerts />}>
            <Route path="access-denied" element={<AccessDeniedPage />} />
            <Route path="" element={<Navigate to="home" />}></Route>
            <Route path="profile" element={<LoggedInProfile />} />
            <Route path="home" element={<Home />}>
              <Route
                path=""
                element={metricsAccess ? <Navigate to="metrics" /> : <Home />}
              ></Route>
              {/* <Route path='renderSurvey' element={<RenderSurvey />}></Route> */}
              <Route element={<PageAccessGuard />}>
                <Route path="alert/create" element={<CreateAlert />}></Route>
                <Route path="alert/:_id" element={<CreateAlert />}></Route>
                <Route path="alert/view/:_id" element={<ViewAlert />}></Route>
                {/* <Route path='alert/sent' element={<TableAlert is_sent={true} />} /> */}
                <Route
                  path="alert/active"
                  element={<TableAlert is_active={true} />}
                />
                <Route
                  path="alert/sent"
                  element={<TableAlert is_sent={true} />}
                />
                <Route
                  path="alert/draft"
                  element={<TableAlert is_draft={true} />}
                ></Route>
                <Route path="survey/create" element={<CreateSurvey />}></Route>
                <Route path="survey/:_id" element={<CreateSurvey />}></Route>
                <Route path="survey/view/:_id" element={<ViewSurvey />}></Route>
                <Route
                  path="survey/active"
                  element={<TableSurvey is_active={true} />}
                ></Route>
                <Route
                  path="survey/sent"
                  element={<TableSurvey is_sent={true} />}
                ></Route>
                <Route
                  path="survey/draft"
                  element={<TableSurvey is_draft={true} />}
                ></Route>
                <Route path="metrics" element={<Metrics />}></Route>
              </Route>
            </Route>
            <Route path="admin" element={<Configuration />}>
              <Route
                path=""
                element={
                  rolesAccess ? <Navigate to="roles" /> : <Configuration />
                }
              ></Route>
              <Route element={<PageAccessGuard />}>
                <Route path="devicemanagement" element={<RecipientsTable />} />

                {/* <Route path='templates' element={<TableAlert is_draft={true} />}></Route> */}
                <Route
                  path="templates/alerts"
                  element={<AlertTemplates />}
                ></Route>
                <Route
                  path="templates/alerts/create"
                  element={<AlertTemplateForm />}
                ></Route>
                <Route
                  path="templates/alerts/:_id"
                  element={<AlertTemplateForm />}
                ></Route>
                {/* <Route path='template/view/:id' element={<Templateview />}></Route> */}
                <Route
                  path="templates/surveys"
                  element={<SurveyTemplates />}
                ></Route>
                <Route
                  path="templates/surveys/create"
                  element={<SurveyTemplateForm />}
                ></Route>
                <Route
                  path="templates/surveys/:_id"
                  element={<SurveyTemplateForm />}
                ></Route>

                <Route path="users" element={<Users />} />
                <Route path="user" element={<UserForm />} />
                <Route path="user/:user_id" element={<UserView />} />
                <Route path="roles" element={<UserRoles />} />
                <Route path="role" element={<UserRoleForm />} />
                <Route path="role/:role_id" element={<UserRoleView />} />
                <Route path="groups" element={<Groups />} />
                <Route path="group" element={<GroupForm />} />
                <Route path="group/:group_id" element={<GroupView />} />
                <Route path="placeholders" element={<CustomizedParameters />} />
                <Route
                  path="placeholder"
                  element={<CustomizedParameterForm />}
                />
                <Route
                  path="placeholder/:parameter_id"
                  element={<CustomizedParameterView />}
                />

                <Route path="metrics" element={<Metrics />} />
              </Route>
            </Route>
          </Route>
        </Route>
      </Routes>
    </React.Suspense>
  );
}

export default Routing;
