import { useEffect, useState , useContext } from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { isNullOrUndefined } from "../../utills/CommonMethods";
import { LoggedInUserContext } from "../../App";
import * as API from "../../utills/API";

const useAuth = () => {
    const [isAuth, setIsAuth] = useState(null);
    const { loggedInUser, setLoggedInUser } = useContext(LoggedInUserContext);

    const location = useLocation();

    useEffect(() => {
        const isExists = async () => {
            if (!isNullOrUndefined(loggedInUser['screens'])) {
                let forms = !isNullOrUndefined(loggedInUser['screens']) ? loggedInUser['screens']['forms'] : [];
                let results = forms.filter(form => location.pathname.toString().startsWith(form.base_path));
                let hasPageAccess = results.length > 0 && results[0].page_level_access ? true : false;
                setIsAuth(hasPageAccess);
            }
            else {
                API.GetMethod("getUserContext").then((user_context_response) => {
                    let user_data = !isNullOrUndefined(user_context_response['data']) ? user_context_response['data'] : {}
                    let forms = !isNullOrUndefined(user_data['screens']) ? user_data['screens']['forms'] : [];
                    let results = forms.filter(form => location.pathname.toString().startsWith(form.base_path));
                    let hasPageAccess = results.length > 0 && results[0].page_level_access ? true : false;
                    setIsAuth(hasPageAccess);
                });
            }

        }
        isExists();
    });

    return isAuth;
}

const PageAccessGuard = () =>{
    const isAuth = useAuth();
    if(isNullOrUndefined(isAuth))
    return null;
    return isAuth ? <Outlet /> : <Navigate  to="/deskalerts/access-denied" />
}


export default PageAccessGuard


