/* eslint-disable no-unused-vars */

import * as XLSX from "xlsx";
import { FiThumbsUp } from "react-icons/fi";
import { MdOutlineErrorOutline } from "react-icons/md";
import * as API from "./API";
import axios from "axios";

export const concateNames = (firstName, middleName, lastName) => {
  return (
    (!isNullOrUndefined(firstName) ? firstName.toString() + " " : "") +
    (!isNullOrUndefined(middleName) ? middleName.toString() + " " : "") +
    (!isNullOrUndefined(lastName) ? lastName.toString() : "")
  );
};

export const removeEmptyObjectFromArray = (data) => {
  return JSON.stringify(data) === "[{}]" ? [] : data;
};

export const isNullOrUndefined = (value) => {
  return (
    value === null ||
    value === undefined ||
    value === "" ||
    (typeof value === "object" ? Object.keys(value).length === 0 : false)
  );
};

export const getProperArrayFormat = (value) => {
  let string_format = JSON.stringify(value);
  let return_format = string_format === "[{}]" ? [] : JSON.parse(string_format);
  return return_format;
};

export const app_support_details = {
  name: "Contact App Support",
  email: "appsupport@accordion.com",
};

export const CapitalizeFirstLetter = (mySentence) => {
  return mySentence.replace(/(^\w{1})|(\s+\w{1})/g, (letter) =>
    letter.toUpperCase()
  );
};

export const exportJSONtoExcel = (data, name) => {
  const worksheet = XLSX.utils.json_to_sheet(data);
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
  XLSX.writeFile(workbook, name);
};

export const exportJSONtoMultipleExcel = (tables, name) => {
  const workbook = XLSX.utils.book_new();

  tables.forEach((table, index) => {
    const worksheet = XLSX.utils.json_to_sheet(table);
    XLSX.utils.book_append_sheet(workbook, worksheet, `Table${index + 1}`);
  });

  XLSX.writeFile(workbook, name);
};

export const copyToClipboard = (text) => {
  var textField = document.createElement("textarea");
  textField.innerText = text;
  document.body.appendChild(textField);
  textField.select();
  document.execCommand("copy");
  textField.remove();
};

export const updateFormFields = (obj1, obj2) => {
  for (let prop of Object.keys(obj2)) {
    if (prop in obj1) {
      if (
        typeof obj2[prop] === "object" &&
        Array.isArray(obj2[prop]) &&
        !isNullOrUndefined(obj2[prop][0])
      ) {
        if (
          !isNullOrUndefined(obj1[prop][0]) &&
          Object.keys(obj1[prop][0]).length
        ) {
          let objectKeys = { ...obj1[prop][0] };
          obj1[prop] = [];
          for (let i = 0; i < obj2[prop].length; i++) {
            let object = { ...objectKeys };
            for (let nestedProp of Object.keys(objectKeys)) {
              if (nestedProp in object) {
                object[nestedProp] = !isNullOrUndefined(
                  obj2[prop][i][nestedProp]
                )
                  ? obj2[prop][i][nestedProp]
                  : objectKeys[nestedProp];
              }
            }
            obj1[prop].push(object);
          }
        } else {
          obj1[prop] = !isNullOrUndefined(obj2[prop]) ? obj2[prop] : obj1[prop];
        }
      } else if (
        typeof obj2[prop] === "object" &&
        !Array.isArray(obj2[prop]) &&
        !isNullOrUndefined(obj2[prop])
      ) {
        if (!isNullOrUndefined(obj1[prop]) && Object.keys(obj1[prop]).length) {
          for (let nestedProp of Object.keys(obj2[prop])) {
            if (nestedProp in obj1[prop]) {
              obj1[prop][nestedProp] = !isNullOrUndefined(
                obj2[prop][nestedProp]
              )
                ? obj2[prop][nestedProp]
                : obj1[prop][nestedProp];
            }
          }
        } else {
          obj1[prop] = !isNullOrUndefined(obj2[prop]) ? obj2[prop] : obj1[prop];
        }
      } else if (typeof obj2[prop] !== "object") {
        obj1[prop] = !isNullOrUndefined(obj2[prop]) ? obj2[prop] : obj1[prop];
      }
    }
  }

  return obj1;
};

export const arrayToString = (arrayName, fieldName) => {
  if (!isNullOrUndefined(arrayName)) {
    let newArray = [];
    for (let i = 0; i < arrayName.length; i++) {
      newArray.push(arrayName[i][fieldName]);
    }
    let arrayData = newArray.join(", ");
    return arrayName.length !== 0 ? arrayData : "NA";
  } else {
    return isNullOrUndefined(arrayName)
      ? "NA"
      : arrayName.length !== 0
        ? ""
        : "NA";
  }
};

export function displayDate(date) {
  let d = new Date(date);
  let months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  return months[d.getMonth()] + ", " + d.getDate() + ", " + d.getFullYear();
}

export function sortByProperty(property) {
  return function (a, b) {
    if (isNullOrUndefined(property)) {
      if (a > b) return 1;
      else if (a < b) return -1;

      return 0;
    } else {
      if (a[property] > b[property]) return 1;
      else if (a[property] < b[property]) return -1;
      return 0;
    }
  };
}

export function customSort(data, key, sortType) {
  if (!isNullOrUndefined(data)) {
    let ascSort = data.sort(sortByProperty(key));
    return sortType === "desc" ? ascSort.reverse() : ascSort;
  } else {
    return [];
  }
}

export function customShowornot(data, key, sortType, show_only_access) {
  if (show_only_access) {
    return data.filter((el) => {
      return el[key] === true;
    });
  } else {
    return customSort(data, key, sortType);
  }
}

export function removeHTMLTags(str) {
  let output;
  if (!isNullOrUndefined(str)) {
    output = str
      .replace(/(<([^>]+)>)/gi, " ")
      .replace(/&nbsp;/gi, " ")
      .replace(/&amp;/gi, "&")
      // .replace(/&nbsp;/,' ')
      .replace(/&lt;/gi, "<")
      .replace(/&gt;/gi, ">")
      .replace(/&quot;/gi, '"')
      .replace(/&apos;/gi, "'")
      .replace(/&cent;/gi, "¢")
      .replace(/&pound;/gi, "£")
      .replace(/&yen;/gi, "¥")
      .replace(/&euro;/gi, "€")
      .replace(/&copy;/gi, "©")
      .replace(/&reg;/gi, "®")
      .trim();
  }
  return output;
}
export function findScheduleType(type) {
  let schedule_type = null;
  if (!isNullOrUndefined(type)) {
    schedule_type = SCHEDULE_TYPES.find((schedule) => schedule.value === type);
  }
  return schedule_type?.label;
}

export function stringHasTheWhiteSpaceOrNot(value) {
  return value.indexOf(" ") >= 0;
}

export function filterData(array, filters) {
  if (!isNullOrUndefined(array) && !isNullOrUndefined(filters)) {
    let filtered_rows = array.filter((item) => {
      let result = false;
      for (var i = 0; i < filters.length; i++) {
        if (filters[i][3] == "==") {
          if (item[filters[i][0]] == filters[i][1]) {
            result = true;
          }
        } else if (filters[i][3] == "!=") {
          if (item[filters[i][0]] != filters[i][1]) {
            result = true;
          }
        } else if (filters[i][3] == "<") {
          if (item[filters[i][0]] < filters[i][1]) {
            result = true;
          }
        } else if (filters[i][3] == ">") {
          if (item[filters[i][0]] > filters[i][1]) {
            result = true;
          }
        } else if (filters[i][3] == "<=") {
          if (item[filters[i][0]] <= filters[i][1]) {
            result = true;
          }
        } else if (filters[i][3] == ">=") {
          if (item[filters[i][0]] >= filters[i][1]) {
            result = true;
          }
        } else {
          if (item[filters[i][0]] == filters[i][1]) {
            result = true;
          }
        }
      }
      return result;
    });

    return filtered_rows;
  } else {
    return [];
  }
}

export function insertobjectToarray(arr, obj, index) {
  if (index < arr.length) {
    arr[index] = obj;
    return arr;
  } else {
    arr.push(obj);
    return arr;
  }
}

export function RANGE_ARRAY(start, end) {
  return Array(end - start + 1)
    .fill()
    .map((_, idx) => start + idx);
}

export function numberFormat(number) {
  return number?.toLocaleString("en-IN");
}

export const statusFieldConversion = (status) => {
  return status ? "Active" : "Inactive";
};

export const yesNOFieldConversion = (status) => {
  return status ? "Yes" : "No";
};

export const err_msg = {
  mail: "Capital letters are not allowed",
  name: "Numbers and special char are not allowed",
  mob: "Invalid mobile number",
  min_ten: "Minimum 10 digits are required",
  max_ten: "Maximum 10 digits are allowed",
  mail_char: "Can not be great than 64 characters",
};

export const getEventInfo = (form_id, base_path, button_value) => {
  let trigger_at = base_path.split("/").pop();
  trigger_at = trigger_at
    .toString()
    .concat("-")
    .concat(button_value.toLowerCase());

  return {
    "Form-Id": form_id,
    "Trigger-At": trigger_at,
  };
};

export const STATUS_POPUP_DETAILS = {
  success: {
    iconName: <FiThumbsUp size="1.4rem" />,
    title: "Success",
    message: "",
    className: "mer-success-dialog",
  },
  error: {
    iconName: <MdOutlineErrorOutline size="1.4rem" />,
    title: "Error",
    message: "",
    className: "mer-error-dialog",
  },
};

export const ratingMarks = (start_value, end_value) => {
  let rating_marks = [];
  for (let i = start_value; i <= end_value; i++) {
    let object = {
      value: i,
      label: i,
      display_opinion: i,
    };
    rating_marks.push(object);
  }
  return rating_marks;
};

export function isDate(_date) {
  const _regExp = new RegExp(
    "^(-?(?:[1-9][0-9]*)?[0-9]{4})-(1[0-2]|0[1-9])-(3[01]|0[1-9]|[12][0-9])T(2[0-3]|[01][0-9]):([0-5][0-9]):([0-5][0-9])(.[0-9]+)?(Z)?$"
  );
  return _regExp.test(_date);
}
export function arrayofNumberswithinLimit(start, end) {
  let array = [];
  for (start; start <= end; start++) {
    let object = { label: `${start}`, value: start };
    array.push(object);
  }
  return array;
}
export const EXCEL_FIELDS = {
  actual: [
    { key: "recipient_name", value: "Recipient Name" },
    { key: "email_id", value: "Email ID" },
  ],
  total: [
    { key: "recipient_name", value: "Recipient Name" },
    { key: "email_id", value: "Email ID" },
    { key: "delivered", value: "Delivered" },
    { key: "acknowledged", value: "Acknowledged" },
    { key: "delivered_on", value: "Delivered On" },
    { key: "acknowledged_on", value: "Acknowledged On" },
  ],
  delivered: [
    { key: "recipient_name", value: "Recipient Name" },
    { key: "email_id", value: "Email ID" },
    { key: "delivered_on", value: "Delivered On" },
    { key: "acknowledged", value: "Acknowledged" },
  ],
  acknowledged: [
    { key: "recipient_name", value: "Recipient Name" },
    { key: "email_id", value: "Email ID" },
    { key: "acknowledged_on", value: "Acknowledged On" },
  ],
  notdelivered: [
    { key: "recipient_name", value: "Recipient Name" },
    { key: "email_id", value: "Email ID" },
  ],
  notacknowledged: [
    { key: "recipient_name", value: "Recipient Name" },
    { key: "email_id", value: "Email ID" },
    { key: "delivered", value: "Delivered" },
  ],
  // 'survey_response': [
  //     { key: 'recipient_name', value: "Recipient Name" },
  //     { key: 'recipient_email_id', value: "Recipient Email ID" },
  //     { key: 'subject', value: "Subject" },
  //     { key: 'question_name', value: "Question Name" },
  //     { key: 'question_type_name', value: "Question Type" },
  //     { key: 'answer', value: "Answer" },
  //     { key: 'acknowledged_on', value: "Acknowledge On" }
  // ]
};

export const SCHEDULE_TYPES = [
  { label: "Immediately", value: "immediately", disable: false },
  { label: "Once At", value: "once-at", disable: false },
  { label: "Daily", value: "daily", disable: true },
  { label: "Weekly", value: "weekly", disable: true },
  { label: "Monthly", value: "monthly", disable: true },
];

export const EXPIRY_TYPES = [
  { label: "Days", value: "days" },
  { label: "Hours", value: "hours" },
];

export const SURVEY_FORMATS = [
  {
    // label: 'Single question per page'
    label: "Yes",
    value: true,
  },
  {
    // label: 'All questions in single page'
    label: "No",
    value: false,
  },
];

export const IS_SNOOZABLE_OPTIONS = [
  {
    // label: 'Single question per page'
    label: "Enable",
    value: true,
  },
  {
    // label: 'All questions in single page'
    label: "Disable",
    value: false,
  },
];

export const WEEKDAYS = [
  { label: "Sunday", value: 0 },
  { label: "Monday", value: 1 },
  { label: "Tuesday", value: 2 },
  { label: "Wednesday", value: 3 },
  { label: "Thursday", value: 4 },
  { label: "Friday", value: 5 },
  { label: "Saturday", value: 6 },
];
export const MONTHDAYS = arrayofNumberswithinLimit(1, 31);

export const ROWS_PER_PAGE = [10, 25, 50, 100];
export const DEFAULT_ROWS_PER_PAGE = 10;
export const ALERT_TIMEOUT = 15000;
export const UPDATE_BUTTON = "Update";
export const CREATE_BUTTON = "Create";
export const ADDNEW_BUTTON = "Add New";

export const reg_mail = /^[^A-Z]*$/;
export const reg_name_with_space = /^[a-zA-Z ]*$/;
export const reg_name_without_space = /^[a-zA-Z]*$/;
export const reg_mob = /^[6-9]+[0-9]*$/;

export const PAGINATION_ROW_LABEL = "Rows :";

export const max_file_size = 8388608;
export const max_file_size_string = "8MB";

export const DIMENSIONS = {
  alert_width: 650,
  alert_height: 450,
  survey_width: 650,
  survey_height: 450,
};

export const setDefaultSubjectStyle = "font-family: Calibri; font-size: 14px;";
export const setDefaultStyle = "font-family: Calibri; font-size: 12px;";

export const fontSizes = [
  8, 9, 10, 11, 12, 14, 16, 18, 20, 22, 24, 26, 28, 36, 48, 72,
];
export const font_families = [
  "Arial",
  "Calibri",
  "Comic Sans MS",
  "Courier New",
  "Imapact",
  "Georgia",
  "tohoma",
  "Trebuchet MS",
  "Verdana",
  "Courier New,Courier",
];

export function handleImageUploadBefore(files, info, uploadHandler) {
  let file = files[0];
  let formData = new FormData();
  formData.append("image", file);
  API.PostMethod("imageUpload", formData).then((response) => {
    let data = {
      result: [
        {
          url: `${process.env.REACT_APP_BASE_URL}${response.data}`,
          name: file.name,
          size: file.size,
        },
      ],
    };
    uploadHandler(data);
  });
}
export function getDaysOfWeek(days_of_week) {
  if (!isNullOrUndefined(days_of_week)) {
    let days = [];
    for (let [i, value] of days_of_week.entries()) {
      let day = WEEKDAYS.find((day) => day.value === value);
      days.push(day);
    }
    return arrayToString(days, "label");
  }
}

export function isSearchParamPresent(param) {
  if (!isNullOrUndefined(window.location.search)) {
    const searchParam = new URLSearchParams(window.location.search);
    return searchParam.has(param);
  }
  return false;
}

export function isObjectEmpty(obj) {
  if (Object.keys(obj).length === 0) {
    return true;
  }
  return false;
}


export async function getLoginUrl() {
  const config = {};
  const login_resp = await axios.get(
    `${process.env.REACT_APP_MICROSOFT_OAUTH_URL}login/getLoginUrl`,
    config
  );
  window.sessionStorage.setItem(
    "verifier_token",
    login_resp?.data?.verifier_jwt
  );
  return login_resp?.data?.login_url;
}

export async function getTokenAndDetails(code) {
  const options = {
    headers: {
      Authorization: `${code}`,
      "Code-Verifier": window.sessionStorage.getItem("verifier_token") ?? "",
    },
  };

  try {
    const responses = await axios.get(
      `${process.env.REACT_APP_MICROSOFT_OAUTH_URL}login/getAccessToken`,
      options
    );

    return responses.data;
  } catch (err) {
    console.log("Retrieving Access Token failed");
    // Logout();
  }
}

export async function setTokensToLocalStorage(token_obj) {
  await window.localStorage.setItem(
    "microsoft_tokens",
    JSON.stringify(token_obj)
  );
}

export async function Logout() {
  window.sessionStorage.clear();
  window.localStorage.clear();
  let redirectLink = process.env.REACT_APP_FRONTEND_URL + "login";
  window.location.replace(redirectLink);
}
